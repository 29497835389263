<template>
  <div>
    {{a}}
    <unicom-camera></unicom-camera>
  </div>
</template>

<script>
import unicomCamera from "@/components/unicomCamera";
export default {
  name: "index",
  components:{
    'unicom-camera':unicomCamera
  },
  data(){
    return{
      a:10
    }
  }
}
</script>

<style scoped>

</style>
