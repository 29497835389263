export class webSock {
    #disconnection_reconnection = true
    #reconnection = 0
    #obj=null
    #heartbeat=null

    constructor({url, open, error, onmessage, send, onclose, errorcallback, reconnection}) {
        //url
        this.url = url
        //打开回调函数
        open && (this.websocketOnOpen = open)
        //错误回调函数
        error && (this.websocketOnError = error)
        //消息回调函数
        onmessage && (this.websocketOnMessage = onmessage)
        //发送消息回调函数
        send && (this.websocketSend = send)
        //关闭回调函数
        onclose && (this.websocketClose = onclose)
        //重连时间 默认2000
        reconnection && (this.#reconnection = reconnection)
        //重连失败回调函数
        errorcallback && (this.websocketOnErrorCallBack = errorcallback)
        //重连标志
        this.#disconnection_reconnection = true
    }

    // 初始化websocket
    initWebSocket() {
        this.websock = new WebSocket(this.url)
        this.websock.onmessage = this.websocketOnMessage
        this.websock.onopen = this.websocketOnOpen
        this.websock.onerror = this.websocketOnError
        this.websock.onclose = this.websocketClose
        this.heartbeat()
    }

    // 连接建立成功的信号
    websocketOnOpen = (e) => {
        this.websocketSend({state: this.#disconnection_reconnection})
    }
    // 连接建立失败重连
    websocketOnError = () => {
        // 如果报错的话，在这里就可以重新初始化websocket，这就是断线重连
        this.#disconnection_reconnection = false
        console.log(this.#reconnection)
        if(this.#obj) return;
       this.#obj = setTimeout(() => {
            this.#reconnection += 1
           clearInterval(this.#heartbeat)
            this.initWebSocket()
           this.#obj = null
        }, 3000)


    }

    // 数据接收
    websocketOnMessage = (e) => {
        console.log('接收内容:', e.data)
    }
    // 数据发送
    websocketSend = (Data) => {

        try {
            let data = JSON.stringify(Data)
            this.websock.send(data)// Data变量就是你想对后台说些啥，根据后端给你的接口文档传值进行交互
        }catch {
            this.websock.send(Data)// Data变量就是你想对后台说些啥，根据后端给你的接口文档传值进行交互
        }

    }
    // 关闭的信号
    websocketClose = () => {
        if (this.#disconnection_reconnection) this.websocketOnError();
    }
    //重连失败回调函数
    websocketOnErrorCallBack = () => {

    }
    heartbeat=()=>{
        if(!this.#heartbeat){

            this.#heartbeat=setInterval(()=>{
                this.websocketSend({state: false})
            },10000)
        }
    }
    // 传参给后端，这里对websocketSend又进行了一层封装，用不到的可以删除
    sendDevName = (chooseDevice) => {
        this.websocketSend(chooseDevice)
    }
}
