import request from '@/utils/request'

let url = 'https://hmclient01.jinhuos.com/prod-api'
// let url = 'http://localhost:8283/dev-api'

// 预警查询
export function queryYjData() {
    return request({
        url: `${url}/tzjc/api/queryYjData`,
        method: 'get',
    })
}

// 列表数据
export function queryFwdxRvData(data) {
    return request({
        url: `${url}/tzjc/api/queryFwdxRvData`,
        method: 'get',
        params:data,
        timeout: 600 * 1000,
    })
}

// 详细数据
export function queryFwdxDetail(data) {
    return request({
        url: `${url}/tzjc/api/queryFwdxDetail/${data}`,
        method: 'get',
    })
}

// SOS
export function querySOS() {
    return request({
        // url: `${url}/tzjc/api/queryFwdxDetail`,
        url: `${url}/tzjc/api/queryFwdxDetllAll`,
        method: 'get',
    })
}

//sos update
export function dealSso(ssoId) {
    return request({
        url: `${url}/tzjc/api/dealSso`,
        method: "post",
        data: {ssoId: ssoId, isdeal: "是"}

    })
}

//Warning update
export function dealWarning(yjdataId, clr, clsj, cljg) {
    return request({
        url: `${url}/tzjc/api/dealWarning`,
        // url:`http://42.193.48.210:8080/healthy/tzjc/api/dealWarning`,
        method: "post",
        data: {yjdataId: yjdataId, isdeal: "是", clr: clr, clsj: clsj, cljg: cljg}

    })
}

// 心率数据查询
export function queryFwdxJkDetail(data) {
    return request({
        url: `${url}/tzjc/api/queryFwdxJkDetail/${data}`,
        method: 'get',
    })
}

// 心率数据查询
export function queryFwdxJkDetailTime(data) {
    return request({
        url: `${url}/tzjc/api/queryFwdxDetailByDateTime`,
        method: 'post',
        data: data,
    })
}

