import Vue from 'vue'
import VueRouter from 'vue-router'
import largemescreen from '@/views/largemescreen/index'
import video from "@/views/video";

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        name: 'largemescreen',
        component: largemescreen
    },
    {
        path: '/video',
        name: "video",
        component: video
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
