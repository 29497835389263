import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import dialogDrag from '@/plugins/drag'
Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/icon/iconfont.css"
Vue.use(ElementUI);
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

import echarts from "echarts";
Vue.prototype.$echarts = echarts;

const install = function(Vue) {
  Vue.directive('dialogDrag', dialogDrag)

}
Vue.use(install); // eslint-disable-line
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
