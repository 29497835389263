import dayjs from 'dayjs'
import people from "@/assets/image/people.png"
import unicomCamera from "@/components/unicomCamera";
import {
    dealSso,
    dealWarning,
    queryFwdxDetail,
    queryFwdxJkDetail, queryFwdxJkDetailTime,
    queryFwdxRvData,
    querySOS,
    queryYjData
} from "@/api/largemescreen";
import * as echarts from 'echarts'
import screenfull from 'screenfull'
import {webSock} from "@/api/websocket";
import Cookies from "js-cookie";

export default {
    name: "largemescreen",
    components: {
        'unicom-camera': unicomCamera
    },

    data() {
        //呼吸图标options
        let huxioption = {
            dataZoom: [{type: 'inside'}, {type: 'slider'}],
            legend: {
                data: ['呼吸']
            },
            xAxis: {
                type: 'category',
                data: [],
                axisLine: {
                    lineStyle: {
                        color: '#fff'
                    }
                },
                fontStyle: {
                    color: '#fff'
                }
            },
            yAxis: {
                type: 'value',
                axisLine: {
                    lineStyle: {
                        color: '#fff'
                    }
                },
                fontStyle: {
                    color: '#fff'
                }
            },
            series: [
                {
                    name: '呼吸',
                    data: [],
                    type: 'line',
                    lineStyle: {
                        color: "#fff",
                        itemStyle: {
                            normal: {
                                color: '#00FF00',
                                lineStyle: {
                                    color: '#00FF00'
                                }
                            }
                        }
                    },
                },
            ]
        }
        //血压图标options
        let xueyaoption = {
            dataZoom: [{type: 'inside'}, {type: 'slider'}],
            legend: {
                data: ['高压', '低压']
            },
            xAxis: {
                type: 'category',
                data: [],
                axisLine: {
                    lineStyle: {
                        color: '#fff'
                    }
                },
                fontStyle: {
                    color: '#fff'
                }
            },
            yAxis: {
                type: 'value',
                axisLine: {
                    lineStyle: {
                        color: '#fff'
                    }
                },
                fontStyle: {
                    color: '#fff'
                }
            },
            series: [
                {
                    name: '高压',
                    data: [],
                    type: 'line',
                    lineStyle: {
                        color: "#fff",
                        itemStyle: {
                            normal: {
                                color: '#00FF00',
                                lineStyle: {
                                    color: '#00FF00'
                                }
                            }
                        }
                    },
                },
                {
                    name: '低压',
                    data: [],
                    type: 'line',
                    lineStyle: {
                        color: "#fff"
                    },
                    itemStyle: {//折线拐点标志的样式
                        borderColor: "#E9CD4B",//拐点的边框颜色
                        borderWidth: 3.5
                    },
                }
            ]
        }
        //血氧图标options
        let xueyangoption = {
            dataZoom: [{type: 'inside'}, {type: 'slider'}],
            legend: {
                data: ['血氧']
            },
            xAxis: {
                type: 'category',
                data: [],
                axisLine: {
                    lineStyle: {
                        color: '#fff'
                    }
                },
                fontStyle: {
                    color: '#fff'
                }
            },
            yAxis: {
                type: 'value',
                axisLine: {
                    lineStyle: {
                        color: '#fff'
                    }
                },
                fontStyle: {
                    color: '#fff'
                }
            },
            series: [
                {
                    name: '血氧',
                    data: [],
                    type: 'line',
                    lineStyle: {
                        color: "#fff",
                        itemStyle: {
                            normal: {
                                color: '#00FF00',
                                lineStyle: {
                                    color: '#00FF00'
                                }
                            }
                        }
                    },
                },
            ]
        }
        //心率图标options
        let xinlvoption = {
            dataZoom: [{type: 'inside'}, {type: 'slider'}],
            legend: {
                data: ['心率']
            },
            xAxis: {
                type: 'category',
                data: [],
                axisLine: {
                    lineStyle: {
                        color: '#fff'
                    }
                },
                fontStyle: {
                    color: '#fff'
                }
            },
            yAxis: {
                type: 'value',
                axisLine: {
                    lineStyle: {
                        color: '#fff'
                    }
                },
                fontStyle: {
                    color: '#fff'
                }
            },
            series: [
                {
                    name: '心率',
                    data: [],
                    type: 'line',
                    lineStyle: {
                        color: "#fff",
                        itemStyle: {
                            normal: {
                                color: '#00FF00',
                                lineStyle: {
                                    color: '#00FF00'
                                }
                            }
                        }
                    },
                },
            ]
        }
        let queryPopupData = {
            //呼吸图表options
            huxioption: {
                dataZoom: [{type: 'inside'}, {type: 'slider'}],
                legend: {
                    data: ['呼吸']
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    axisLine: {
                        lineStyle: {
                            color: '#fff'
                        }
                    },
                    fontStyle: {
                        color: '#fff'
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        lineStyle: {
                            color: '#fff'
                        }
                    },
                    fontStyle: {
                        color: '#fff'
                    }
                },
                series: [
                    {
                        name: '呼吸',
                        data: [],
                        type: 'line',
                        lineStyle: {
                            color: "#fff",
                            itemStyle: {
                                normal: {
                                    color: '#00FF00',
                                    lineStyle: {
                                        color: '#00FF00'
                                    }
                                }
                            }
                        },
                    },
                ]
            },
            //血压图表options
            xueyaoption: {
                dataZoom: [{type: 'inside'}, {type: 'slider'}],
                legend: {
                    data: ['高压', '低压']
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    axisLine: {
                        lineStyle: {
                            color: '#fff'
                        }
                    },
                    fontStyle: {
                        color: '#fff'
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        lineStyle: {
                            color: '#fff'
                        }
                    },
                    fontStyle: {
                        color: '#fff'
                    }
                },
                series: [
                    {
                        name: '高压',
                        data: [],
                        type: 'line',
                        lineStyle: {
                            color: "#fff",
                            itemStyle: {
                                normal: {
                                    color: '#00FF00',
                                    lineStyle: {
                                        color: '#00FF00'
                                    }
                                }
                            }
                        },
                    },
                    {
                        name: '低压',
                        data: [],
                        type: 'line',
                        lineStyle: {
                            color: "#fff"
                        },
                        itemStyle: {//折线拐点标志的样式
                            borderColor: "#E9CD4B",//拐点的边框颜色
                            borderWidth: 3.5
                        },
                    }
                ]
            },
            //血氧图表options
            xueyangoption: {
                dataZoom: [{type: 'inside'}, {type: 'slider'}],
                legend: {
                    data: ['血氧']
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    axisLine: {
                        lineStyle: {
                            color: '#fff'
                        }
                    },
                    fontStyle: {
                        color: '#fff'
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        lineStyle: {
                            color: '#fff'
                        }
                    },
                    fontStyle: {
                        color: '#fff'
                    }
                },
                series: [
                    {
                        name: '血氧',
                        data: [],
                        type: 'line',
                        lineStyle: {
                            color: "#fff",
                            itemStyle: {
                                normal: {
                                    color: '#00FF00',
                                    lineStyle: {
                                        color: '#00FF00'
                                    }
                                }
                            }
                        },
                    },
                ]
            },
            //心率图表options
            xinlvoption: {
                dataZoom: [{type: 'inside'}, {type: 'slider'}],
                legend: {
                    data: ['心率']
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    axisLine: {
                        lineStyle: {
                            color: '#fff'
                        }
                    },
                    fontStyle: {
                        color: '#fff'
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        lineStyle: {
                            color: '#fff'
                        }
                    },
                    fontStyle: {
                        color: '#fff'
                    }
                },
                series: [
                    {
                        name: '心率',
                        data: [],
                        type: 'line',
                        lineStyle: {
                            color: "#fff",
                            itemStyle: {
                                normal: {
                                    color: '#00FF00',
                                    lineStyle: {
                                        color: '#00FF00'
                                    }
                                }
                            }
                        },
                    },
                ]
            },
        }

        return {
            rules: {
                handler: [
                    {required: true, message: '警告处理人不能为空', trigger: 'blur'}
                ],
                result: [
                    {required: true, message: '警告处理结果不能为空', trigger: 'blur'}
                ]
            },
            SXTurl: "",
            dayjsobj: null,
            sleepstatus: {'1': '在床', '4': '离床'},
            motion: {"1": "正常", "2": "体动"},
            people: people,
            number: 999,
            Intersection: null,
            Intersectionmark: false,
            heartconfig: {
                number: [0],
                content: '{nt}',
                style: {
                    fill: '#f33d09'
                }
            },
            respiratoryconfig: {
                number: [0],
                content: '{nt}',
                style: {
                    fill: '#f33d09'
                }
            },
            motionconfig: {
                number: [0],
                content: '{nt}',
                style: {
                    fill: '#f33d09'
                }
            },
            pressureconfig: {
                number: [0],
                content: '{nt}',
                style: {
                    fill: '#f33d09'
                }
            },
            oxygenconfig: {
                number: [0],
                content: '{nt}',
                style: {
                    fill: '#f33d09'
                }
            },
            listconfig: {
                header: ['姓名', '警告事件', '发生时间', '操作'],
                data: [],
                align: ['center', 'center', 'center', 'center'],
                idlist: [],
                waitTime: 2000,
            },
            input: '',
            userlistinfo: [],
            userlistinfoback: [],
            userdetail: {},
            //呼吸图标options
            huxioption,

            //血压图标options
            xueyaoption,

            //血氧图标options
            xueyangoption,

            //心率图标options
            xinlvoption,

            dialogVisible: false,
            form: {},
            videoUrl: '',
            videoopen: false,
            voiceUrl: '',
            voiceopen: false,
            isactive: false,
            visible: false,
            Detailloading: false,
            warningsetInterval: null,
            FwdxRvDatasetInterval: null,
            detailssetInterval: null,
            SOSsetInterval: null,
            echarshuxi: null,
            echarsxueya: null,
            echarsxueyang: null,
            echarsxinlv: null,
            FwdxDetailId: null,
            sosshowlist: [],
            time: '',
            mywebsocket: null,
            uuids: '',

            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近半年',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一年',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            queryTimeNodes: null,
            //     查询弹窗
            queryPopup: false,
            queryPopupobj: null,
            queryPopuploading: false,
            queryPopupstarte: false,
            queryPopupData,

        }
    },
    methods: {
        init() {
            this.fullScreen()
            this.FwdxRvData()
            this.initecharshuxi()
            this.initecharsxueya()
            this.initecharsxueyang()
            this.initecharsxinlv()
            // this.warningsetInterval = setInterval(() => {
            //   this.YjData()
            // }, 5000)
            this.FwdxRvDatasetInterval = setInterval(() => {
                this.FwdxRvData()
            }, 60000)
            // this.SOSsetInterval = setInterval(() => {
            //   this.SOS()
            // }, 1000)
            this.Intersection = new IntersectionObserver((ob) => {
                this.Intersectionmark = ob[0].isIntersecting
            });
            this.Intersection.observe(this.$refs["eltable-bottom"])

        },
        initwebid() {
            let uuid = Cookies.get('uuid')
            if (uuid) {
                this.uuids = uuid
            } else {
                this.uuids = this.uuid()
                Cookies.set('uuid', this.uuid())
            }
            this.mywebsocket = new webSock(
                {
                    //访问url
                    url: `${document.location.protocol.indexOf('https') !== -1 ? 'wss' : 'ws'}://${location.host}/socket/wbapi/pushMessage/${this.uuids}`,
                    // url: `${'ws'}://${'localhost:8283'}/healthy/wbapi/pushMessage/${this.uuids}`,
                    // url: `ws://hmclient01.jinhuos.com/socket/healthy/wbapi/pushMessage/${this.uuids}`,
                    //数据接收处理函数

                    onmessage: this.websocketHandle,


                })
            this.mywebsocket.initWebSocket()
        },
        /**
         * 跳转到底部
         */
        gotobottom() {
            this.$nextTick(() => {
                this.$refs.tableList.bodyWrapper.scrollTo({
                    top: this.$refs.tableList.bodyWrapper.scrollHeight,
                    behavior: "smooth"
                })
            })
        },
        fullScreen() {
            if (!screenfull.isEnabled) {
                this.$message({message: '你的浏览器不支持全屏', type: 'warning'})
                return false
            }
            if (screenfull.isEnabled) {
                screenfull.on('change', this.change)
            }
            screenfull.toggle()
        },
        change() {
            this.isFullscreen = screenfull.isFullscreen
            // if(this.isFullscreen){
            //   document.getElementsByClassName('sidebar-container has-logo')[0].style.display='none'
            // }else {
            //   document.getElementsByClassName('sidebar-container has-logo')[0].style.display=''
            // }
        },
        /**
         * 查询预警数据
         */
        YjData(data) {
            // queryYjData().then((response) => {
            //   if (response.code === 200) {
            //
            //     let data = response.data
            //     this.heartconfig.number[0] = parseInt(data.type.heart)
            //     this.respiratoryconfig.number[0] = parseInt(data.type.respiratory)
            //     this.motionconfig.number[0] = parseInt(data.type.motion)
            //     this.pressureconfig.number[0] = parseInt(data.type.pressure)
            //     this.oxygenconfig.number[0] = parseInt(data.type.oxygen)
            //     this.heartconfig = {...this.heartconfig}
            //     this.respiratoryconfig = {...this.respiratoryconfig}
            //     this.motionconfig = {...this.motionconfig}
            //     this.pressureconfig = {...this.pressureconfig}
            //     this.oxygenconfig = {...this.oxygenconfig}
            //     data.list.forEach((item, index, arr) => {
            //       let list = [item.username, item.type, item.time, '<span style="color:skyblue;user-select: none" value=' + item.userid + '>查看</span>']
            //       this.listconfig.data.push(list)
            //       this.$refs.warningList.updateRows(this.listconfig.data)
            //     })
            //   }
            // })
            this.heartconfig.number[0] = parseInt(data.type.heart)
            this.respiratoryconfig.number[0] = parseInt(data.type.respiratory)
            this.motionconfig.number[0] = parseInt(data.type.motion)
            this.pressureconfig.number[0] = parseInt(data.type.pressure)
            this.oxygenconfig.number[0] = parseInt(data.type.oxygen)
            this.heartconfig = {...this.heartconfig}
            this.respiratoryconfig = {...this.respiratoryconfig}
            this.motionconfig = {...this.motionconfig}
            this.pressureconfig = {...this.pressureconfig}
            this.oxygenconfig = {...this.oxygenconfig}
            data.list.forEach((item, index, arr) => {

                if (this.listconfig.idlist.indexOf(item.id) === -1) {
                    let list = [item.username, item.type, this.dayjsobj(parseInt(item.time)).format('HH:mm:ss'), '<span style="user-select: none" value=' + item.userid + ' id=' + item.id + '>查看</span>']
                    this.listconfig.idlist.push(item.id)
                    this.listconfig.data.push(list)
                    this.$refs.warningList.updateRows(this.listconfig.data)

                }
            })
        },
        /**
         * 患者管理
         */
        FwdxRvData(istrue) {
            queryFwdxRvData({pageSize: 0}).then((response) => {

                if (response.code === 200) {
                    this.userlistinfo = response.rows
                    this.userlistinfoback = this.userlistinfo
                }
                if (JSON.stringify(this.userdetail) === '{}' && this.userlistinfo.length > 0) {

                    this.FwdxDetail(this.userlistinfo[0].userid)
                    this.FwdxJkDetail(this.userlistinfo[0])
                } else {
                    let datas = this.userlistinfo.filter((item) => {
                        return item.userid === this.FwdxDetailId
                    })

                    if (istrue) {
                        if (this.queryPopup) return
                        queryFwdxJkDetail(this.FwdxDetailId).then((response) => {
                            if (this.queryPopupstarte) {
                                this.queryPopupstarte = false
                                this.huxioption.series[0].data = []
                                this.xueyaoption.series[0].data = []
                                this.xueyaoption.series[1].data = []
                                this.xueyangoption.series[0].data = []
                                this.xinlvoption.series[0].data = []

                                this.huxioption.xAxis.data = []
                                this.xueyaoption.xAxis.data = []
                                this.xueyangoption.xAxis.data = []
                                this.xinlvoption.xAxis.data = []
                            }
                            let data = response.rows
                            for (let i = 0; i < data.length - 1; i++) {
                                let obj = data[i]
                                obj.respiratory && this.huxioption.series[0].data.push(parseInt(obj.respiratory.split(',')[0]))
                                //高压
                                obj.dbp && this.xueyaoption.series[1].data.push(parseInt(obj.dbp.split(',')[0]))
                                //低压
                                obj.sbp && this.xueyaoption.series[0].data.push(parseInt(obj.sbp.split(',')[0]))
                                obj.oxygen && this.xueyangoption.series[0].data.push(parseInt(obj.oxygen.split(',')[0]))
                                obj.heart && this.xinlvoption.series[0].data.push(parseInt(obj.heart.split(',')[0]))

                                obj.respiratory && this.huxioption.xAxis.data.push(this.dayjsobj(parseInt(obj.respiratory.split(',')[1])).format('HH:mm:ss'))
                                obj.dbp && this.xueyaoption.xAxis.data.push(this.dayjsobj(parseInt(obj.dbp.split(',')[1])).format('HH:mm:ss'))
                                obj.oxygen && this.xueyangoption.xAxis.data.push(this.dayjsobj(parseInt(obj.oxygen.split(',')[1])).format('HH:mm:ss'))
                                obj.heart && this.xinlvoption.xAxis.data.push(this.dayjsobj(parseInt(obj.heart.split(',')[1])).format('HH:mm:ss'))
                            }
                            this.FwdxJkDetail(datas[0])
                        })
                    } else {
                        if (this.queryPopup) return
                        if (this.queryPopupstarte) {
                            this.queryPopupstarte = false
                            this.huxioption.series[0].data = []
                            this.xueyaoption.series[0].data = []
                            this.xueyaoption.series[1].data = []
                            this.xueyangoption.series[0].data = []
                            this.xinlvoption.series[0].data = []

                            this.huxioption.xAxis.data = []
                            this.xueyaoption.xAxis.data = []
                            this.xueyangoption.xAxis.data = []
                            this.xinlvoption.xAxis.data = []
                        }
                        this.FwdxJkDetail(datas[0])
                    }
                }
            })
        },
        /**
         * 患者详细数据
         */
        FwdxDetail(id) {

            this.FwdxDetailId = id
            this.Detailloading = true
            queryFwdxDetail(id).then((response) => {
                if (response.code === 200) {
                    this.userdetail = response.data
                }
                this.Detailloading = false
            }).catch(() => {
                this.Detailloading = false
            })

        },
        /**
         * 查看详情
         */
        viewdetails(row) {

            let pattern = /\d+/g
            let aa = row.ceil.match(pattern)
            let userid = aa[0]
            let warningid = aa[1]
            this.listconfig.idlist = this.listconfig.idlist.filter(item => {
                return parseInt(item) !== parseInt(warningid)
            })
            this.dialogVisible = true
            this.form.warningid = warningid
            this.form.row = row
            this.FwdxDetail(userid)
        },
        /**
         * 提交警告数据
         */
        submitForm() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    // 处理时间
                    const now = new Date();
                    const year = now.getFullYear();
                    const month = now.getMonth() + 1;
                    const date = now.getDate();
                    const hours = now.getHours();
                    const minutes = now.getMinutes();
                    const seconds = now.getSeconds();
                    const formattedDate = `${year}/${month}/${date} ${hours}:${minutes}:${seconds}`;
                    //处理结果
                    //处理人
                    dealWarning(this.form.warningid, this.form.handler, formattedDate, this.form.result).then(() => {
                        this.$message.success("处理成功,请在大屏查看具体数据")
                        this.listconfig.data = this.listconfig.data.filter(item => {
                            return item !== this.form.row.row
                        })
                        this.$refs.warningList.updateRows(this.listconfig.data)
                        this.mywebsocket.websocketSend({state: true})
                        this.form = {}
                    })
                    this.dialogVisible = false;
                } else {
                    this.$message.error('表单验证失败！');
                }
            });
        },
        /**
         * 获取图表数据
         */
        FwdxJkDetail(data) {
            if (data) {
                let heart, respiratory, sbp, dbp, oxygen
                heart = data.mattress.heart
                respiratory = data.mattress.respiratory
                dbp = data.bracelet.dbp
                sbp = data.bracelet.sbp
                oxygen = data.bracelet.oxygen
                //呼吸
                respiratory && this.huxioption.series[0].data.push(parseInt(respiratory))

                //高压
                dbp && this.xueyaoption.series[1].data.push(parseInt(dbp))
                //低压
                dbp && this.xueyaoption.series[0].data.push(parseInt(sbp))

                //血氧
                oxygen && this.xueyangoption.series[0].data.push(parseInt(oxygen))

                //心率
                heart && this.xinlvoption.series[0].data.push(parseInt(heart))

                this.huxioption.xAxis.data.push(this.dayjsobj(parseInt(data.datatime)).format('HH:mm:ss'))
                this.xueyaoption.xAxis.data.push(this.dayjsobj(parseInt(data.datatime)).format('HH:mm:ss'))
                this.xueyangoption.xAxis.data.push(this.dayjsobj(parseInt(data.datatime)).format('HH:mm:ss'))
                this.xinlvoption.xAxis.data.push(this.dayjsobj(parseInt(data.datatime)).format('HH:mm:ss'))


                this.echarshuxi.setOption(this.huxioption)
                this.echarsxueya.setOption(this.xueyaoption)
                this.echarsxueyang.setOption(this.xueyangoption)
                this.echarsxinlv.setOption(this.xinlvoption)
                if (this.huxioption.series[0].data.length >= 11) {
                    this.huxioption.series[0].data.shift()
                }
                if (this.huxioption.xAxis.data.length >= 11) {
                    this.huxioption.xAxis.data.shift()
                }

                if (this.xueyaoption.series[0].data.length >= 11) {
                    this.xueyaoption.series[0].data.shift()
                }
                if (this.xueyaoption.xAxis.data.length >= 11) {
                    this.xueyaoption.xAxis.data.shift()
                }
                if (this.xueyangoption.series[0].data.length >= 11) {
                    this.xueyangoption.series[0].data.shift()
                }
                if (this.xueyangoption.xAxis.data.length >= 11) {
                    this.xueyangoption.xAxis.data.shift()
                }
                if (this.xinlvoption.series[0].data.length >= 11) {
                    this.xinlvoption.series[0].data.shift()
                }
                if (this.xinlvoption.xAxis.data.length >= 11) {
                    this.xinlvoption.xAxis.data.shift()
                }
            }

        },
        /**
         *
         * @param date 传入时间字符串
         * @returns {string}  转换为 时间格式yyyy-MM-dd HH:mm:ss
         */
        formatDate(spcoe) {
            if (spcoe.datatime) {
                return this.dayjsobj(parseInt(spcoe.datatime)).format('YYYY-MM-DD HH:mm:ss')
            } else {
                return '--'
            }


        },
        formatDateOther(makre, date) {
            var date = makre ? new Date(parseInt(date)) : new Date();
            var YY = date.getFullYear() + '-';
            var MM = date.getMonth() < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
            var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            var hh = date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ':';
            var mm = date.getMinutes() < 10 ? '0' + date.getMinutes() + ":" : date.getMinutes() + ':';
            var ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            if (makre) {
                return hh + mm + ss;
            } else {
                return YY + MM + DD + " " + hh + mm + ss;
            }
        },

        /**
         * 筛选患者
         */
        screeningpatients() {
            if (this.input) {
                // let screen = []
                // this.userlistinfoback.forEach((item) => {
                //     let a = RegExp("^" + this.input + '$')
                //     if (a.test(item.username) || a.test(item.usercode) || a.test(item.bedcode)) {
                //         screen.push(item)
                //     }
                // })
                // this.userlistinfo = screen
                queryFwdxRvData({pageSize: 0,param:this.input}).then((response) => {

                        if (response.code === 200) {
                            this.userlistinfo = response.rows
                            this.userlistinfoback = this.userlistinfo
                        }
                    }
                )
            }else {
                this.$message.error("无效搜索,请输入搜索值")
            }
            // else {
            //     this.userlistinfo = this.userlistinfoback
            // }

        },
        /**
         * 初始化echars表
         */
        initecharshuxi() {
            this.echarshuxi = echarts.init(this.$refs.huxi)
            this.echarshuxi.setOption(this.huxioption)
        },
        initecharsxueya() {
            this.echarsxueya = echarts.init(this.$refs.xueya)
            this.echarsxueya.setOption(this.xueyaoption)
        },
        initecharsxueyang() {
            this.echarsxueyang = echarts.init(this.$refs.xueyang)
            this.echarsxueyang.setOption(this.xueyangoption)
        },
        initecharsxinlv() {
            this.echarsxinlv = echarts.init(this.$refs.xinlv)
            this.echarsxinlv.setOption(this.xinlvoption)
        },
        SOS(data) {
            // querySOS().then((response) => {
            //   let data = response.rows
            //   for (let i = 0; i < data.length; i++) {
            //     if (this.sosshowlist.indexOf(data[i].datatime) === -1) {
            //       this.sosshowlist.push(data[i].datatime)
            //       let aa = this.$notify({
            //         title: '一键呼叫',
            //         dangerouslyUseHTMLString: true,
            //         message: `${data[i].username} ${data[i].sex ? '男' : '女'} <span style="color:red">SOS</span> 服务对象详情详情:${data[i].Warningdata ? data[i].Warningdata : '--'} <button>查看详情</button>`,
            //         duration: 0,
            //         onClick: () => {
            //           this.FwdxDetail(data[i].userid)
            //           this.sosshowlist = this.sosshowlist.filter((item) => {
            //             return item !== data[i].datatime
            //           })
            //           dealSso(parseInt(data[i].datatime)).then(() => {
            //             this.$message.success("处理成功,请在大屏查看具体数据")
            //           })
            //           aa.close()
            //         }
            //       });
            //     }
            //   }
            // })
            for (let i = 0; i < data.length; i++) {
                if (this.sosshowlist.indexOf(data[i].id) === -1) {
                    this.sosshowlist.push(data[i].id)
                    let aa = this.$notify({
                        title: '一键呼叫',
                        dangerouslyUseHTMLString: true,
                        message: `${data[i].username} ${data[i].sex ? '男' : '女'} <span style="color:red">SOS</span> 服务对象详情详情:${data[i].Warningdata ? data[i].Warningdata : '--'}<button>查看详情</button>`,
                        duration: 0,
                        position: 'bottom-right',
                        onClick: () => {
                            this.FwdxDetail(data[i].userid)
                            this.sosshowlist = this.sosshowlist.filter((item) => {
                                return item !== data[i].id
                            })
                            dealSso(parseInt(data[i].id)).then(() => {
                                this.$message.success("处理成功,请在大屏查看具体数据")
                            })
                            aa.close()
                        }
                    });
                }
            }
            this.$refs.audio.currentTime = 0
            this.$refs.audio.play()
        },
        /**
         * 传入服务对象信息，打开事件提示窗
         * @param informationDetails
         */
        oneClickCallOperation(informationDetails) {
            let aa = this.$notify(
                {
                    title: `${informationDetails.fwdx.nickName} 呼叫`,
                    dangerouslyUseHTMLString: true,
                    message: `${informationDetails.fwdx.sex ? '男' : '女'} 发起时间:${informationDetails.voice.dataTimeStr ? informationDetails.voice.dataTimeStr : '--'}<br>地址:${informationDetails.fwdx.addrs ? informationDetails.fwdx.addrs : '-'}&nbsp&nbsp &nbsp&nbsp &nbsp&nbsp &nbsp&nbsp <span style="width: 50px;text-align: center;height: 45px;line-height: 45px;border: #00afff solid 1px;background: #8180f8">接受呼叫</span>`,
                    duration: 0,
                    position: 'bottom-right',
                    onClick: () => {
                        this.voiceUrl = informationDetails.voice.web
                        this.voiceopen = true
                        aa.close()
                    }
                }
            )
        },
        tableRowClassName({row, rowIndex}) {
            if (row.userid === this.FwdxDetailId) {
                return 'select-row';
            } else if (!this.FwdxDetailId && rowIndex === 0) {
                return 'select-row';
            }
            return '';
        },
        /**
         * websocket 数据接受处理
         */
        websocketHandle(e) {
            try {
                let data = JSON.parse(e.data)
                if (data.warning) {
                    this.YjData(data.warning)
                }
                if (data.sos) {
                    this.SOS(data.sos)
                }
                if (data.voice) {
                    //oneClickCallOperation。。。 data.voice  data.fwdx
                    this.oneClickCallOperation(data)

                }
            } catch (E) {

            }

        },
        /**
         * 生成随机前端ID
         * @returns {string}
         */
        uuid() {
            var d = new Date().getTime();
            if (window.performance && typeof window.performance.now === "function") {
                d += performance.now(); //use high-precision timer if available
            }
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (d + Math.random() * 16) % 16 | 0;    // d是随机种子
                d = Math.floor(d / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        },

        openSXT() {
            if (this.userdetail.videosrc) {
                // this.visible = true;
                this.videoUrl = "https://aiot.ultwiz.com/shareInfo" + this.userdetail.videosrc;
                this.videoopen = true

                // window.open(
                //     this.SXTurl, '单独窗口',
                //     'height=600,width=1000,top=200,left=350,toolbar=no,menubar=no, scrollbars=no,resizable=no,location=no, status=no,'
                // )


            } else {
                this.$message("该用户未绑定摄像头");
            }

        },
        /**
         * 查看回放
         */
        openSXTOld() {
            if (this.userdetail.previewRePlayUrl) {
                // this.visible = true;
                this.videoUrl = "https://aiot.ultwiz.com/shareInfo" + this.userdetail.previewRePlayUrl;
                this.videoopen = true

                // window.open(
                //     this.SXTurl, '单独窗口',
                //     'height=600,width=1000,top=200,left=350,toolbar=no,menubar=no, scrollbars=no,resizable=no,location=no, status=no,'
                // )


            } else {
                this.$message("该用户未绑定摄像头");
            }

        },
        /**
         * 根据时间段查询心跳信息
         */
        queryTime() {
            if (!this.queryPopupobj) {
                this.queryPopup = true


                this.queryTime = () => {
                    this.queryPopup = true
                    this.queryPopupstarte = true
                    if (this.queryTimeNodes) {
                        this.huxioption.series[0].data = []
                        this.xueyaoption.series[0].data = []
                        this.xueyaoption.series[1].data = []
                        this.xueyangoption.series[0].data = []
                        this.xinlvoption.series[0].data = []

                        this.huxioption.xAxis.data = []
                        this.xueyaoption.xAxis.data = []
                        this.xueyangoption.xAxis.data = []
                        this.xinlvoption.xAxis.data = []
                        queryFwdxJkDetailTime({
                            fwdxId: this.FwdxDetailId,
                            beginTime: this.queryTimeNodes[0],
                            endTime: this.queryTimeNodes[1]
                        }).then(res => {

                            if (res.code === 200 && res.rows.length > 0) {
                                this.huxioption.series[0].data = []
                                this.xueyaoption.series[1].data = []
                                this.xueyaoption.series[0].data = []
                                this.xueyangoption.series[0].data = []
                                this.xinlvoption.series[0].data = []
                                this.huxioption.xAxis.data = []
                                this.xueyaoption.xAxis.data = []
                                this.xueyangoption.xAxis.data = []
                                this.xinlvoption.xAxis.data = []
                                // 打开弹窗
                                res.rows.forEach((item, index) => {
                                    let heart = item.heart
                                    let respiratory = item.respiratory
                                    let dbp = item.dbp
                                    let sbp = item.sbp
                                    let oxygen = item.oxygen

                                    respiratory ? this.huxioption.series[0].data.push(parseInt(respiratory.split(',')[0])) : ''
                                    //高压
                                    dbp ? this.xueyaoption.series[1].data.push(parseInt(dbp.split(',')[0])) : ''
                                    //低压
                                    sbp ? this.xueyaoption.series[0].data.push(parseInt(sbp.split(',')[0])) : ''
                                    oxygen ? this.xueyangoption.series[0].data.push(parseInt(oxygen.split(',')[0])) : ''
                                    heart ? this.xinlvoption.series[0].data.push(parseInt(heart.split(',')[0])) : ''
                                    respiratory ? this.huxioption.xAxis.data.push(this.dayjsobj(parseInt(respiratory.split(',')[1])).format('HH:mm:ss')) : ''
                                    sbp ? this.xueyaoption.xAxis.data.push(this.dayjsobj(parseInt(sbp.split(',')[1])).format('HH:mm:ss')) : ''
                                    oxygen ? this.xueyangoption.xAxis.data.push(this.dayjsobj(parseInt(oxygen.split(',')[1])).format('HH:mm:ss')) : ''
                                    heart ? this.xinlvoption.xAxis.data.push(this.dayjsobj(parseInt(heart.split(',')[1])).format('HH:mm:ss')) : ''
                                })
                                this.echarshuxi.setOption(this.huxioption)
                                this.echarsxueya.setOption(this.xueyaoption)
                                this.echarsxueyang.setOption(this.xueyangoption)
                                this.echarsxinlv.setOption(this.xinlvoption)
                            }
                        })
                    } else {
                        this.$message.error('请选择时间段')
                    }
                }
                this.queryTime()
            } else {
                this.queryTime = () => {
                    this.queryPopup = true
                    this.queryPopupstarte = true
                    if (this.queryTimeNodes) {
                        this.huxioption.series[0].data = []
                        this.xueyaoption.series[0].data = []
                        this.xueyaoption.series[1].data = []
                        this.xueyangoption.series[0].data = []
                        this.xinlvoption.series[0].data = []

                        this.huxioption.xAxis.data = []
                        this.xueyaoption.xAxis.data = []
                        this.xueyangoption.xAxis.data = []
                        this.xinlvoption.xAxis.data = []
                        queryFwdxJkDetailTime({
                            fwdxId: this.FwdxDetailId,
                            beginTime: this.queryTimeNodes[0],
                            endTime: this.queryTimeNodes[1]
                        }).then(res => {

                            if (res.code === 200 && res.rows.length > 0) {
                                // 打开弹窗
                                res.rows.forEach((item, index) => {
                                    let heart = item.heart
                                    let respiratory = item.respiratory
                                    let dbp = item.dbp
                                    let sbp = item.sbp
                                    let oxygen = item.oxygen

                                    respiratory ? this.huxioption.series[0].data.push(parseInt(respiratory.split(',')[0])) : ''
                                    //高压
                                    dbp ? this.xueyaoption.series[1].data.push(parseInt(dbp.split(',')[0])) : ''
                                    //低压
                                    sbp ? this.xueyaoption.series[0].data.push(parseInt(sbp.split(',')[0])) : ''
                                    oxygen ? this.xueyangoption.series[0].data.push(parseInt(oxygen.split(',')[0])) : ''
                                    heart ? this.xinlvoption.series[0].data.push(parseInt(heart.split(',')[0])) : ''
                                    respiratory ? this.huxioption.xAxis.data.push(this.dayjsobj(parseInt(respiratory.split(',')[1])).format('HH:mm:ss')) : ''
                                    sbp ? this.xueyaoption.xAxis.data.push(this.dayjsobj(parseInt(sbp.split(',')[1])).format('HH:mm:ss')) : ''
                                    oxygen ? this.xueyangoption.xAxis.data.push(this.dayjsobj(parseInt(oxygen.split(',')[1])).format('HH:mm:ss')) : ''
                                    heart ? this.xinlvoption.xAxis.data.push(this.dayjsobj(parseInt(heart.split(',')[1])).format('HH:mm:ss')) : ''
                                    this.echarshuxi.setOption(this.huxioption)
                                    this.echarsxueya.setOption(this.xueyaoption)
                                    this.echarsxueyang.setOption(this.xueyangoption)
                                    this.echarsxinlv.setOption(this.xinlvoption)
                                })
                            }
                        })
                    } else {
                        this.$message.error('请选择时间段')
                    }
                }
                this.queryTime()
            }


        }

    }
    ,
    mounted() {
        this.dayjsobj = dayjs
        setTimeout(() => {
            this.init()
        }, 500)
        setInterval(() => {
            this.time = this.dayjsobj(parseInt(Date.parse(new Date()))).format('YYYY-MM-DD HH:mm:ss')
        }, 1000)
        // 初始化websocket
        this.initwebid()

    }
    ,
    watch: {
        FwdxDetailId() {
            this.huxioption.series[0].data = []
            this.huxioption.xAxis.data = []
            this.xueyaoption.series[0].data = []
            this.xueyaoption.series[1].data = []
            this.xueyaoption.xAxis.data = []
            this.xueyangoption.series[0].data = []
            this.xueyangoption.xAxis.data = []
            this.xinlvoption.series[0].data = []
            this.xinlvoption.xAxis.data = []
            this.echarshuxi.setOption(this.huxioption)
            this.echarsxueya.setOption(this.xueyaoption)
            this.echarsxueyang.setOption(this.xueyangoption)
            this.echarsxinlv.setOption(this.xinlvoption)
            this.FwdxRvData(true)
        }
    }
    ,
    destroyed() {
        clearInterval(this.warningsetInterval)
        clearInterval(this.FwdxRvDatasetInterval)
        clearInterval(this.detailssetInterval)
        clearInterval(this.SOSsetInterval)
        this.Intersection.disconnect()
        if (screenfull.isEnabled) {
            screenfull.off('change', this.change)
        }
    },
    queryPopup() {
        this.huxioption.series[0].data = []
        this.xueyaoption.series[0].data = []
        this.xueyaoption.series[1].data = []
        this.xueyangoption.series[0].data = []
        this.xinlvoption.series[0].data = []
        this.huxioption.xAxis.data = []
        this.xueyaoption.xAxis.data = []
        this.xueyangoption.xAxis.data = []
        this.xinlvoption.xAxis.data = []
    }

}