<template>
  <el-dialog
      :visible="visible"
      v-bind="$attrs"
      v-on="$listeners"
      v-if="visible"
      @close="close"
      :append-to-body="true"
      v-loading="loading"
      element-loading-text="视频拼命打开中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div style="width: 100%;height: calc(100% - 50px)" ref="box">

      <canvas id="canvas1" :width="width" :height="height"></canvas>
    </div>
    <div style="width: 100%;height: 50px">
      <el-button type="primary" @mousedown.native="ptz_ctrl_up" @mouseup.native="ptz_ctrl_stop">云台上</el-button>
      <el-button type="primary" @mousedown.native="ptz_ctrl_down" @mouseup.native="ptz_ctrl_stop">云台下</el-button>
      <el-button type="primary" @mousedown.native="ptz_ctrl_left" @mouseup.native="ptz_ctrl_stop">云台左</el-button>
      <el-button type="primary" @mousedown.native="ptz_ctrl_right" @mouseup.native="ptz_ctrl_stop">云台右</el-button>

    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "unicomCamera",
  props: {
    devid: {
      type: String,
      default: () => "5161619700"
    },
    value: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      channel: 0,
      streamtype: 0,
      visible: false,
      loading: false,
      width: 702,
      height: 576,
    }
  },
  methods: {
    /**
     * 初始化canvas
     */
    init() {
      if(!this.devid){
        this.$message.warning("未关联摄像头,打开失败")
        return
      }
      this.loading = true
      let canvas = document.getElementById("canvas1")
      ConnectApi.onloginresult = (api_conn, result) => {
        if (result == 0) {
          api_conn.logined = true;
          console.log(api_conn.deviceid + '登录成功');
          for (let i = 0; i < api_conn.streamlist.length; i++) {
            if (api_conn.streamlist[i].winindex >= 0 && api_conn.connectType == 1) {
              ConnectApi.open_stream(api_conn, i, api_conn.streamlist[i].streamid);
              playerList[api_conn.streamlist[i].winindex].open()
            }
          }
        } else {
          console.log((api_conn.deviceid ? api_conn.deviceid : api_conn.ip) + '登录失败 ' + '错误码：' + result);
        }
        this.openvideo()
      }
      ConnectApi.onopenstream = () => {
        this.loading = false
      }
      this.width = this.$refs.box.offsetWidth
      this.height = this.width / 16 * 9

      Player.init([canvas])
      setTimeout(() => {
        this.connect()
      }, 500)
    },
    /**
     * 链接摄像头,登录
     */
    connect() {

      Player.ConnectDevice(this.devid, '', 'admin', '', 0, 80, 0, this.channel, this.streamtype)
      setTimeout(()=>{
        if(this.loading){
          this.loading=false
          this.$message.error("打开摄像头失败,请检查网络或配置是否正确")
        }
      },5000)
    },
    /**
     * 断开摄像头链接
     */
    disconnect() {
      Player.DisConnectDevice(this.devid)
    },
    /**
     * 打开摄像头视频
     */
    openvideo() {
      Player.OpenStream(this.devid, '', this.channel, this.streamtype, 0);
    },
    /**
     * 关闭摄像头视频
     */
    closevideo() {
      Player.CloseStream(0)
    },
    /**
     * 切换码流
     */
    changestream() {
      if (this.streamtype == 0) {
        this.streamtype = 1;
      } else {
        this.streamtype = 0;
      }
      Player.ChangeStream(this.devid, '', this.channel, this.streamtype)
    },
    /**
     * 截图
     */
    snapshot() {
      let list = getRecordList();
      console.log(list)
      let playbackNum = 0;
      let startTime = list[playbackNum].file_begintime
      let endTime = list[playbackNum].file_endtime
      Player.StartPlayBack(this.devid, '', this.channel, startTime, endTime, list[playbackNum].file_type, 0, false)
    },
    /**
     * 云台上
     */
    ptz_ctrl_up() {
      console.log('上')
      Player.ptz_ctrl(this.devid, '', this.channel, 2, 6)
    },
    /**
     * 云台下
     */
    ptz_ctrl_down() {
      console.log('下')
      Player.ptz_ctrl(this.devid, '', this.channel, 3, 6)
    },
    /**
     * 云台左
     */
    ptz_ctrl_left() {
      console.log('左')
      Player.ptz_ctrl(this.devid, '', this.channel, 4, 6)
    },
    /**
     * 云台右
     */
    ptz_ctrl_right() {
      console.log('右')
      Player.ptz_ctrl(this.devid, '', this.channel, 5, 6)
    },
    /**
     * 云台移动停止
     */
    ptz_ctrl_stop() {

      Player.ptz_ctrl(this.devid, '', this.channel, 0, 0)
    },
    /**
     * 关闭弹窗
     */
    close() {
      this.$emit("input", false)
      this.closevideo()
      this.disconnect()
    }

  },
  mounted() {
  },
  watch: {
    value(value) {
      if (value) {
        setTimeout(
            () => {
              this.init()
            }
            , 1000)

      }
      this.visible = value
    },

  }
}
</script>

<style scoped>
>>> .el-dialog {
  margin-top: 7vh !important;
}

>>> .el-dialog__body, >>> .el-dialog__header {
  background-color: #525288;
}
</style>
